<template>
  <div>
  <!--Heading with image-->
   <template v-for='(item, index) in homeObj.heading'>
    <v-img width="100%" :height="$vuetify.breakpoint.xlOnly ? 650 : $vuetify.breakpoint.lgOnly  ? 600 : ''"
      :src="item.background_image" :key="`heading-${index}`"
      class="d-flex align-center img-gradient transparent-box"
      >
      <v-container class="apply-index" :class="$vuetify.breakpoint.lgAndUp ? 'apply-width': ''">
        <v-row class="ma-0" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''">
          <v-col class="" xl="7" lg="7" xs="12" sm="12" md="12" >
            <v-container class="pa-15">
                <!-- <h1 class="font-weight-bold mb-4" :style="$vuetify.breakpoint.smAndUp ? 'color:#ffffff !important;  line-height: 60px;' : 'color:#ffffff !important; '"
                 :class="$vuetify.breakpoint.smAndUp ? 'text-h3': 'text-h6'">{{ item.title }}</h1> -->
              <h1 class="font-weight-bold mb-4 white--text" :style="$vuetify.breakpoint.xlOnly ? 'color:#ffffff !important;  line-height: 50px;' : 'color:#ffffff !important; '"
                :class="$vuetify.breakpoint.xlOnly ? 'text-h3': $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.mdOnly
                 || $vuetify.breakpoint.smOnly ? 'text-h5': 'text-h6'">{{ item.title }}</h1>
               <p class="mt-1 text-subtitle-1" style="color:#9AB5C2 !important">{{ item.description }}</p>
              <v-row class=" mt-5">
              <v-col cols="12" class="pa-0">
                <v-btn class="rounded-pill no-text-transform transparent-element" width="220"
                :samll=" $vuetify.breakpoint.mdAndDown"
                :x-large=" $vuetify.breakpoint.lgAndUp"
                color="#87F390" @click="$router.push('/demo')"> Book a demo
                </v-btn>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-btn
                  width="220"
                  v-for="(item, index) in homeObj.Link"
                  :key="`main-button-${index}`"
                  :class="['white--text rounded no-text-transform rounded-pill mr-2 mt-2', $vuetify.breakpoint.lgAndUp ? 'mt-2' : '']"
                  color="#4A99D3"
                  :samll=" $vuetify.breakpoint.mdAndDown"
                  :x-large=" $vuetify.breakpoint.lgAndUp"
                  :href="item.link" target="_blank"
                >
                  <v-row justify="center">
                    <v-col cols="3">
                      <!-- <v-icon size="40" color="#ffffff">mdi-google-play</v-icon> -->
                       <v-img  contain aspect-ratio="2" height="30" class="mb- mt-2" :src="item.link_image"></v-img>
                    </v-col>
                    <v-col cols="9" class="pa-4">
                      <v-list-item-title class="mt-1" style="font-size:10px">{{ item.link_title }}</v-list-item-title>
                      <v-list-item-subtitle class="white--text" style="font-size:16px; margin-right:1px">{{ item.link_subtitle }}</v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col xl="5" lg="5" xs="12" sm="12" md="12">
          <!-- <v-img  contain aspect-ratio="2" height="500" class="mt-3" src="./../../src/assets/images/home.png"></v-img> -->
          <v-img  contain aspect-ratio="2" height="500" class="mt-3" :src="item.image"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
   </template>
   <!--Explainer -->
   <template v-for="(item, index) in homeObj.explainer">
    <v-row justify="center"  :class="$vuetify.breakpoint.smAndUp ? 'explainer-clip': ''"
      class="apply-background-home pa-10 ma-0" :key="`explainer-${index}`">
      <v-col class="d-flex justify-center" xs="12" md="12" lg="12" sm="12" xl="12">
        <div>
          <iframe
            :src="embedUrl"
            :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
            :height=" $vuetify.breakpoint.mdAndUp ? 500 : ''"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </v-col>
    </v-row>
   </template>
   <!--cards-->
   <v-container class="py-10" :class="$vuetify.breakpoint.lgAndUp ? 'apply-width': ''">
    <v-row justify="center" class="ma-0">
      <v-col cols="12" class="pa-10" v-for="(item, index) in homeObj.business" :key="`card-${index}`" >
        <p class="text-center font-weight-bold" :class="$vuetify.breakpoint.smAndUp ? 'text-h4': 'text-h6'">{{ item.business_title }}</p>
        <p class="text-center pa-2">{{ item.business_description }}</p>
      </v-col>
      <v-col xl="5" lg="6" sm="6" xs="12" md="6" v-for=" (item , index) in (homeObj.business_card ? homeObj.business_card.slice(0, 2) : [] )" :key="`busniess-card-${index}`" class="reveal-card fade-bottom-card">
        <v-card tile height="460" class="reveal fade-bottom d-flex align-center card-shadow">
          <v-container>
            <v-card-title>
            <v-row justify="center">
              <v-col cols="12" class="pa-4 d-flex justify-center">
                <v-avatar size="200">
                  <v-img  height="100%" contain aspect-ratio="2" :src="item.business_logo"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-list three-line class="mx-3 apply-scroll" :class=" $vuetify.breakpoint.lgAndUp ? 'mx-0' : ''">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title class="apply-text-color text-h6">{{ item.business_heading }}</v-list-item-title>
                    <v-list-item-subtitle :class=" ['apply-text-color-subtitle',
                      $vuetify.breakpoint.lgAndUp ? 'text-h6' : 'text-size-apply', 'mt-2']" style="color:#3949AB !important">{{ item.business_subtitle }}</v-list-item-subtitle>
                    <v-list-item-content class="pa-0">
                      <p class="mt-2" :class="[ $vuetify.breakpoint.mdAndUp ? 'text-size-apply' : 'text-size-apply']"
                      :title="item.business_details">{{ item.business_details }} </p>
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
      <v-col xl="5" lg="6" sm="6" xs="12" md="6" v-for=" (item , index) in (homeObj.business_card ? homeObj.business_card.slice(2, 4) : [])" :key="`second-busniess-card-${index}`" class="reveal-card fade-bottom-card">
        <v-card tile height="450" class="reveal fade-bottom d-flex align-center card-shadow ">
          <v-container :class="$vuetify.breakpoint.xs ? 'pa-0 mb-10' : ''">
            <v-row justify="center">
              <v-col cols="12" class="pa-4 d-flex justify-center">
                <v-avatar size="200">
                  <v-img  height="100%" contain aspect-ratio="2" :src="item.business_logo"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
            <v-card-text class="pa-0">
             <v-list three-line class="mx-3 apply-scroll" :class=" $vuetify.breakpoint.lgAndUp ? 'mx-0' : ''">
              <v-list-item class="pa-0">
                <v-list-item-content class="pa-0 ">
                  <v-list-item-title class="apply-text-color text-h6">{{ item.business_heading }}</v-list-item-title>
                  <v-list-item-subtitle :class=" ['apply-text-color-subtitle',
                    $vuetify.breakpoint.lgAndUp ? 'text-h6' : 'text-size-apply', 'mt-2']" style="color:#3949AB !important">{{ item.business_subtitle }}</v-list-item-subtitle>
                  <v-list-item-content class="pa-0 ">
                    <p class="mt-2" :class="[ $vuetify.breakpoint.mdAndUp ? 'text-size-apply' : 'text-size-apply']"
                    :title="item.business_details">{{ item.business_details }} </p>
                  </v-list-item-content>
                </v-list-item-content>
              </v-list-item>
             </v-list>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
   </v-container>
  <!--How its works-->
  <template v-if="isHome">
    <v-row class="apply-background-home ma-0 pa-10">
      <v-container :class="$vuetify.breakpoint.lgAndUp ? 'apply-width': ''">
        <v-row justify="center" :class="$vuetify.breakpoint.xsOnly ? 'apply-column' : ''">
          <v-col xs="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
            <p class="font-weight-bold text-center"
              :class="$vuetify.breakpoint.mdAndDown ? 'text-h5': 'text-h4'"
              style="color:#4A99D3">How it works</p>
          </v-col>
          <v-col class="apply-row" xl="3" lg="4" xs="12" md="4" sm="4" v-for="(item , index) in homeObj.rental_info" :key="`how_its-${index}`">
            <v-container>
              <v-card flat class="apply-background-home" >
                <v-row justify="center" class="apply-background-home">
                  <v-col cols="12" class="d-flex justify-center pa-0">
                    <v-card-actions>
                      <v-icon size="50" color='#D3844A'>{{item.icon }}</v-icon>
                      <!-- <v-img :src="item.works_logo" max-width="50" max-height="50"></v-img> -->
                    </v-card-actions>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex justify-center">
                    <v-card-title class="pa-0 text-h6">{{ item.works_heading }}</v-card-title>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex justify-center">
                    <p style="width:150px" class="pa-0 text-center text-subtitle-2">{{ item.works_description }}</p>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </template>
  <!-- Guiding -->
  <v-row class="apply-step-background  ma-0 pa-10" justify="center"  v-if="isHome">
    <v-container :class="$vuetify.breakpoint.lgAndUp ? 'apply-width': ''">
      <template v-for=" (item , index) in homeObj.guiding">
        <v-row justify="center" :key="`guiding-${index}`">
          <v-col cols="12" class="pa-0 pt-2 my-4">
            <p class="font-weight-bold white--text text-center text-h4" style="color:#4A99D3">{{ item.interaction_title }}</p>
          </v-col>
          <v-col cols="8" class="pa-0">
            <p class="font-weight-medium white--text text-center text-subtitle-2" style="color:#4A99D3">{{ item.interaction_description }}</p>
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.lgAndUp">
            <v-stepper flat class="apply-step-background white--text apply-stepper-height"
              height="100" alt-labels>
              <v-stepper-header>
                <template v-for="(item, index) in steperData">
                  <v-stepper-step color="#ffffff" style="color:#000000 !important" class="apply-stepper-height" :step="item.value" :key="`stepper-${index}`">
                    <span class="white--text"> {{item.text}}</span>
                  </v-stepper-step>
                  <v-divider v-if="index < 4" style="background-color: #ffffff" :key="`stepper-divider-${index}`"></v-divider>
                </template>
              </v-stepper-header>
            </v-stepper>
          </v-col>
           <v-col cols="12" class="pa-0" v-if="$vuetify.breakpoint.mdAndDown">
            <v-container class=" d-flex justify-center">
              <v-card flat class="apply-step-background ">
                <v-list-item  v-for="(item, index) in steperData" :key="`stepper-${index}`">
                  <v-avatar size="60" class="ma-4 mr-0" color="#ffffff">
                    <span class="font-weight-bold  text-h5" style="color:#3667BA !important;">{{index+1}}</span>
                  </v-avatar>
                  <span class="white--text ml-2 font-weight-bold text-h6"> {{item.text}}</span>
                  <!-- <span class="font-weight-bold white--text text-h6">{{index+1}}.</span><span class="white--text ml-2 font-weight-bold text-h6"> {{item.text}}</span> -->
                </v-list-item>
              </v-card>
            </v-container>
           <!-- <v-stepper flat class="apply-step-background white--text apply-stepper-height" v-model="e6" vertical alt-labels>
            <template v-for="(item, index) in steperData">
              <v-stepper-step color="#ffffff" :step="item.value" :key="`stepper-${index}`">
                <span class="white--text"> {{item.text}}</span>
              </v-stepper-step>
            </template>
          </v-stepper> -->
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-row>
   <!--Digitalize-->
   <v-container :class="$vuetify.breakpoint.lgAndUp ? 'apply-width': ''">
    <template v-for=" (item , index) in homeObj.guiding">
      <v-container :key="`digitalize-${index}`" class="pa-10">
        <v-row justify="center">
          <v-col cols="12" class="pa-0 pt-2 ">
            <p class="font-weight-bold  text-center text-h4" style="color:#53596D">{{ item.digitalize_title }}</p>
          </v-col>
          <v-col cols="9" class="pa-0">
            <p class="text-center text-subtitle-1" style="color:#53596D">{{ item.digitalize_description }}</p>
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-container >
              <v-carousel hide-delimiters height="300" class="mx-3">
                <template v-for="(item, index) in  homeObj.industries">
                  <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="`carousel-${index}`">
                    <v-row class="flex-nowrap pb-10" style="height:100%">
                      <template v-for="(n,i) in columns">
                        <template v-if="(+index + i) <  homeObj.industries.length">
                          <v-col :key="i"  xl="6" lg="6" sm="12" md="12" xs="12">
                            <v-card  flat v-if="(+index + i) < homeObj.industries.length" height="100%">
                              <a :href="item.url"  v-if="(+index + i) < homeObj.industries.length"
                                @click="$router.push(`${homeObj.industries[+index + i].industries_url}`)">
                                <v-img class="scaling-element" height="100%" v-if="(+index + i) < homeObj.industries.length" style="width: calc(100% - 14px) !important;"
                                  :src="homeObj.industries[+index + i].industries_image">
                                </v-img>
                              </a>
                              <div class="text-center text-subtitle-1 font-weight-bold  pointer scaling-element mt-3" style="color:#53596D">{{homeObj.industries[+index + i].industries_title}}</div>
                              <div class="text-center text-subtitle-1 font-weight-medium pointer scaling-element" style="color:#35A4FE"
                              @click="$router.push(`${homeObj.industries[+index + i].industries_url}`)">see more</div>
                            </v-card>
                          </v-col>
                        </template>
                      </template>
                    </v-row>
                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      </template>
   </v-container>
   <!--overview-->
   <v-row class="apply-background-home-how-its-work rounded-xl ma-0" >
    <v-container :class="$vuetify.breakpoint.lgAndUp ? 'apply-width': ''" class="pa-10">
      <template v-for="(item , index) in homeObj.over_view">
        <v-row justify="center" :key="`overview-${index}`" :class="$vuetify.breakpoint.xsOnly ? 'apply-column' : ''">
          <v-col lg="6" xl="6" sm="6" md="6" xs="12" class="pa-10">
            <v-container>
              <v-img :src="item.overview_image"></v-img>
            </v-container>
          </v-col>
          <v-col class="d-flex align-center" lg="6" xl="6" sm="6" md="6" xs="12">
            <v-container>
              <div class="font-weight-bold text-center text-h5 pa-2">{{item.overview_title}}</div>
              <div class="text-center text-subtitle-1 pa-4" style="color:#53596D"> {{item.overview_description }}</div>
              <v-row class=" mt-3 ml-1" justify="center">
                <v-btn
                    width="220"
                    v-for="(item, index) in homeObj.Link"
                    :key="`main-button-${index}`"
                    class="white--text rounded no-text-transform rounded-pill my-1 ml-2"
                    color="#4A99D3"
                    :samll=" $vuetify.breakpoint.mdAndDown"
                    :x-large=" $vuetify.breakpoint.lgAndUp"
                    href="https://play.google.com/store/apps/details?id=com.processdrive.rentass" target="_blank"
                  >
                    <v-row justify="center">
                      <v-col cols="3">
                        <v-img  contain aspect-ratio="2" height="30" class="mb- mt-2" :src="item.link_image"></v-img>
                      </v-col>
                      <v-col cols="9" class="pa-4">
                        <v-list-item-title class="mt-1" style="font-size:10px">{{ item.link_title }}</v-list-item-title>
                        <v-list-item-subtitle class="white--text" style="font-size:16px; margin-right:1px">{{ item.link_subtitle }}</v-list-item-subtitle>
                      </v-col>
                    </v-row>
                </v-btn>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </template>
    </v-container>
   </v-row>
  </div>
</template>

<script>
import { api } from '../plugins/axios'
import CommonFunctions from '@/mixins/animation.js'
export default {
  mixins: [CommonFunctions],
  data () {
    return {
      loadIcons: ['mdi-google-play'],
      links: ['https://play.google.com/store/apps/details?id=com.processdrive.rentass', 'https://apps.apple.com/in/app/rentass/id6499262645'],
      howItWorksIcons: ['mdi-account', 'mdi-marker-check', 'mdi-emoticon-happy'],
      steperData: [
        {
          text: 'Create an Assets',
          value: 1
        },
        {
          text: 'Rentout',
          value: 2
        },
        {
          text: 'Receiving',
          value: 3
        },
        {
          text: 'Invoicing',
          value: 4
        },
        {
          text: 'Reporting',
          value: 5
        }
      ],
      homeObj: {},
      e6: 1,
      isHome: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.reveal)
    this.getHome()
  },
  computed: {
    embedUrl () {
      const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
      const url = this.youtubeURL.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
      if (YId === url[0]) {
        console.log('not a youtube link')
      } else {
        console.log('its  a youtube video')
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId)
      return topOfQueue
    },
    columns () {
      if (this.$vuetify.breakpoint.xl) {
        return 2
      }
      if (this.$vuetify.breakpoint.lg) {
        return 2
      }
      return 1
    },
    youtubeURL () {
      return this.homeObj.explainer[0].explainer_video
    }
  },
  methods: {
    getHome () {
      api.get('/home')
        .then((response) => {
          this.homeObj = response.data
          this.isHome = true
          if (this.homeObj && this.homeObj.rental_info) {
            this.homeObj.rental_info.forEach((element, index) => {
              if (this.howItWorksIcons[index]) element.icon = this.howItWorksIcons[index]
            })
          }
          if (this.homeObj && this.homeObj.Link) {
            this.homeObj.Link.forEach((linkItem, index) => {
              linkItem.link = this.links[index]
            })
          }
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    }
  }
}
</script>
<style>
.apply-width {
  width: 60% !important;
}
.apply-column {
  display: flex !important;
  flex-direction: column !important;
}
.apply-row {
  display: flex !important;
  flex-direction: row !important;
}

.apply-background-home {
  background: #EDF5FB
}
.card-shadow {
  /* box-shadow: 7px 7px 12px rgb(153, 213, 156,0.3) !important; */
  box-shadow: rgba(153, 213, 156,0.9) 0px 5px 15px !important;
}
.apply-step-background {
  background: #3667BA !important;
}
.v-stepper__header {
 box-shadow: none !important
}
.v-stepper--alt-labels .v-stepper__step__step {
  padding: 30px !important
}
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
    /* background: rgba(223, 87, 87, 0.6) !important; */
     background: rgba(255, 255, 255) !important;
       color: #3667BA !important;
       font-size: 2em !important;
       font-weight: bold !important;
}
.v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 24px;
    position: relative;
    bottom: 20px !important;
    color: #3667BA !important;
}

.v-stepper__step__step {
  /* background: rgba(223, 87, 87, 0.6) !important; */
       background: rgba(255, 255, 255) !important;
       color: #3667BA !important;
       font-size: 2em !important;
       font-weight: bold !important;
}
:hover.v-stepper__step__step {
    box-shadow: 0 0 20px #3667BA !important; /* Add
}
.apply-background-how-its-work {
  /* background: #BBDEFB !important; */
  background: #E3F2FD !important;
}
.text-line-height {
         line-height: 2em !important;
    }
.picture {
    background-blend-mode: screen !important;
   -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%) !important;
    clip-path:polygon(0 0, 100% 0, 100% 85%, 0 100%) !important;
}
.transparent-element {
  opacity: 0.85; /* Adjust the value between 0 and 1 */
}
.text-size-apply {
  font-size: 1.1em !important;
  line-height: 1.7em !important;
}
.apply-scroll {
  overflow: auto !important;
  height: 200px !important;
  overscroll-behavior: contain !important;
}
/* .apply-scroll::-webkit-scrollbar {
   display: none  !important;
   background: #888 !important;
} */

.apply-scroll::-webkit-scrollbar {
  width: 3px;
}
/* Track */
.apply-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}
/* Handle */
.apply-scroll::-webkit-scrollbar-thumb {
  background: rgb(237, 234, 234) !important;
  border-radius: 10px !important;
}

/* Track and Handle for Firefox */
@-moz-document url-prefix() {
  .apply-scroll {
    scrollbar-color: rgb(237, 234, 234) grey;
    scrollbar-width: thin; /* Adjust to make it thinner */
  }

  .apply-scroll::-moz-scrollbar-thumb {
    background-color: rgb(237, 234, 234);
    border-radius: 10px;
  }

  .apply-scroll::-moz-scrollbar-track {
    background-color: grey;
    border-radius: 10px;
  }
}

</style>
